import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Layout from "../layout";
import TinyPhoto from "../components/TinyPhoto/TinyPhoto";
import config from "../../data/SiteConfig";

class Error404Page extends Component {
  render() {
    return (
      <Layout>
        <div className="singlePage">
          <Helmet title={`Missing Page ♡ ${config.siteTitle}`} />
          <div>
            <p align="center">
              It seems that page is missing... <img src="/emoji/bw2.gif" alt="" />
            </p>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Error404Page;